@mixin fontFamily($prop) {
    font-family: #{$prop}, sans-serif;
}

@mixin transition($props, $duration: 0.3s) {
    transition: #{$props} #{$duration};
}

@mixin fontSize($value) {
    $remSize: $value / 16px;
    font-size: #{$remSize}rem;
}

@mixin propPxToEm($property, $value) {
    $emSize: $value / 16px;
    #{$property}: #{$emSize}em;
}
@mixin propPxToRem($property, $value) {
    $emSize: $value / 16px;
    #{$property}: #{$emSize}rem;
}
