.active-live-chat {
  .container {
    // > .video-js {
    //   > video {
    //     height: 100% !important;
    //     width: 100% !important;
    //   }
    // }
    // > .player {

    // }
    > .player {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.container {
  position: fixed;
  height: 100%;
  // display: flex;
  // align-items: center;
  background: #000000;
  &.active-live-chat {
    width: calc(100% - 364px);
    > .player {
      width: 100%;
    }
    // > .video-js {
    //   > video {
    //     width: 100%;
    //   }
    // }
  }
  &:not(.active-live-chat) {
    width: 100%;
    > .player {
      width: 80%;
    }
    // > .video-js {
    //   > video {
    //     width: 80%;
    //   }
    // }
  }

  > .player {
    justify-content: center;
    align-items: center;
    display: flex;
    // height: 100%;
    width: 100%;
    margin: auto;
    height: 80%;
  }

  > .player > .video-js {
    // justify-content: center;
    // align-items: center;
    // display: flex;
    height: 95%;
    width: 100%;
    > video:focus {
      outline: none;
    }
  }

  &.focused:not(.active-header) {
    .controls-container,
    .controls-container > div {
      margin-bottom: -100px !important;
    }
    .media-header,
    .media-header > div {
      margin-top: -150px !important;
    }
    cursor: none;
  }

  .controls-container,
  .controls-container > div {
    margin-bottom: -100px;
    transition: margin-bottom 1s;
  }

  .media-header,
  .media-header > div {
    margin-top: -150px;
    transition: margin-top 1s;
  }

  &.active-header {
    .controls-container,
    .controls-container > div {
      margin-bottom: 0px;
    }

    .media-header,
    .media-header > div {
      margin-top: 0px;
    }
  }
}

.vjs-remaining-time,
.vjs-seek-to-live-control,
.vjs-progress-control.vjs-control {
  visibility: hidden;
}
// .vjs-fullscreen-control.vjs-control.vjs-button,

.vjs-remaining-time-display {
  display: none;
}

.vjs-seek-to-live-control,
.video-js.vjs-control-bar,
.vjs-control-bar,
.vjs-control.vjs-button {
  background: none;
  background-color: transparent !important;
}

@media only screen and (min-width: 1025px) {
  .container {
    display: flex;
    align-items: center;
    &:hover {
      .controls-container,
      .controls-container > div {
        margin-bottom: 0px;
      }

      .media-header,
      .media-header > div {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    &.active-live-chat {
      width: 100% !important;
      position: sticky;

      > .player > .video-js {
        height: auto !important;
      }
    }
    &:not(.active-live-chat) {
      > .player {
        height: 100% !important;
      }
      > .player > .video-js {
        height: 100%;
      }
    }
    > .player {
      height: auto;
      width: 100% !important;
    }
    > .player > .video-js {
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100% !important;
      > video {
        width: 100% !important;
        margin: auto;
        position: inherit;
      }
    }
  }
}
