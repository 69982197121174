@import "../../sass/mixins.scss";
@import "../../sass/effects/fade-in.scss";

.container,
.background,
.backgroundMask,
.blur {
  height: 100%;
  width: 100%;
  position: fixed;

  letter-spacing: 0.125px;
}

.background,
.backgroundMask {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.background {
  background-image: url("../../assets/images/razorback.jpeg");
  background-size: cover;
  background-position: center center;

  .blur {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    height: 100vh;
    width: 100%;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
}

.backgroundMask {
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: 0px 0px 140px 10px #000000d4 inset;
}

.eventWrapper {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  transition: all 0.5s ease-in;
}

.eventInfo {
  width: 50%;
  z-index: 3;
  color: white;
  margin: 0;
  padding: 0 40px 0 180px;
  transition: all 0.5s ease-in;

  p,
  a,
  h1 {
    opacity: 0;
    @include fadeIn();
  }

  p.streamState,
  p.eventDateTime,
  p.organizer,
  h1 {
    font-family: "montserratmedium";
    margin: 0;
  }

  p.streamState {
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    margin: 8px 0;
    animation-delay: 1.2s;
  }

  p.eventDateTime {
    animation-delay: 1s;
  }

  p.officialHashtag {
    @include fontFamily("mulibold");
    animation-delay: 0.8s;
  }

  h1 {
    @include fontFamily("montserratbold");
    margin: 0;
    font-weight: bold;
    font-size: 36px;
    line-height: 130%;
    animation-delay: 0.6s;
  }

  p.organizer {
    @include fontFamily("muliregular");
    margin-top: 8px;
    margin-bottom: 50px;
    animation-delay: 0.4s;
  }
}

.shareBoxWrapper {
  z-index: 3;
  width: 50%;
  padding: 0 180px 0 40px;
  transition: all 0.5s ease-in;

  .shareBox {
    padding: 40px 40px;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    float: left;
    box-shadow: 0px 4px 16px 3px rgba(21, 21, 23, 0.74);

    .shareLabel,
    .shareDescription {
      width: 100%;
      display: inline-block;
      font-style: normal;
    }

    .shareLabel {
      @include fontFamily("montserratbold");
      color: #0d0557;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin-bottom: 20px;
    }

    .shareDescription {
      @include fontFamily("muliregular");
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.125px;
      color: #8b8998;
      margin-bottom: 24px;
    }

    .shareSocial {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: 10px;
      }
    }

    .copyLinkWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      input {
        @include fontFamily("muliregular");
        padding: 8px 20px;
        font-size: 14px;
        line-height: 24px;
        width: 200px;
        background: #f7f6ff;
        border: none;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        border-color: white;
        text-overflow: ellipsis;
      }

      button {
        background: #00c670;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        color: white;
        padding: 8px 28px 8px 24px;
        line-height: 24px;
        font-size: 14px;
        border-color: white;
        cursor: pointer;
      }
    }
  }
}

a {
  color: white;
  margin-top: 5px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .eventInfo {
    padding: 0 40px 0 40px;
  }
  .shareBoxWrapper {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 800px) {
  .eventWrapper {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .eventInfo,
  .shareBoxWrapper {
    width: 90%;
    margin: 20px 0;
    padding: 0;
  }

  .shareBoxWrapper {
    display: inline-flex;
  }
}
