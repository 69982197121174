.media-header {
  position: fixed;
  top: 0px;
  width: inherit;
  height: 150px;
  z-index: 2;
  color: #ffff;
  > .background {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: -10px;
    width: inherit;
    height: 160px;
    filter: blur(100%);
    -webkit-filter: blur(8px);
  }
  > .content {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    width: inherit;
    height: 140px;
    z-index: 2;
    justify-content: space-between;
    > div {
      margin: 0px 30px;
    }
  }

  .header {
    > .title {
      font-size: 18px;
      font-family: "montserratbold";
      font-weight: 500;
      margin-bottom: 8px;
    }
    > .description {
      font-size: 14px;
      font-family: "muliregular";
      margin-bottom: 14px;
    }

    > .status {
      display: flex;
      align-items: center;
      > div {
        margin-right: 10px;
      }
      > .live {
        background: #fc2f35;
        border-radius: 2px;
        padding: 4px 8px;
        font-family: "montserratbold";
        font-weight: 500;
        font-size: 12px;
        // text-transform: uppercase;
      }
      > .viewers {
        font-family: "montserratbold";
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        > i {
          margin-right: 8px;
          font-size: 13px;
        }
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    > div {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      margin-left: 10px;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.2);
      font-size: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      > i {
        margin: auto;
      }
    }
  }
}
