@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?o4lwa6');
  src:  url('fonts/icomoon.eot?o4lwa6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?o4lwa6') format('truetype'),
    url('fonts/icomoon.woff?o4lwa6') format('woff'),
    url('fonts/icomoon.svg?o4lwa6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sound-mute:before {
  content: "\e90c";
}
.icon-play:before {
  content: "\e90b";
}
.icon-clique-colored .path1:before {
  content: "\e900";
  color: rgb(255, 74, 122);
}
.icon-clique-colored .path2:before {
  content: "\e901";
  margin-left: -0.962890625em;
  color: rgb(134, 122, 233);
}
.icon-clique-colored .path3:before {
  content: "\e902";
  margin-left: -0.962890625em;
  color: rgb(0, 198, 112);
}
.icon-clique-colored .path4:before {
  content: "\e903";
  margin-left: -0.962890625em;
  color: rgb(0, 216, 245);
}
.icon-clique:before {
  content: "\e904";
}
.icon-fullscreen:before {
  content: "\e905";
}
.icon-message:before {
  content: "\e906";
}
.icon-pause:before {
  content: "\e907";
}
.icon-share:before {
  content: "\e908";
}
.icon-sound-active:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90a";
}
.icon-exit-fullscreen:before {
  content: "\e90d";
}
