@font-face {
  font-family: "montserratbold";
  src: url("montserrat-bold-webfont.woff2") format("woff2"),
    url("montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratextrabold";
  src: url("montserrat-extrabold-webfont.woff2") format("woff2"),
    url("montserrat-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratmedium";
  src: url("Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "muliregular";
  src: url("muli-webfont.woff2") format("woff2"),
    url("muli-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "mulibold";
  src: url("muli-bold-webfont.woff2") format("woff2"),
    url("muli-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
